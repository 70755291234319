<template>
    <div class="main-container-vacantes">
        <div style="width: 50%; display: flex; flex-direction: row; margin-top: 2%;">
            <form class="input-group" @submit.prevent="getCompany()">
                <input type="search" class="form-control" v-model="search"
                    placeholder="Buscar por título de vacante, compañia/división..." />
            </form>
            <div class="dropdown" style="margin-left: 2%;">
                <button class="btn border-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Filtrar por Status
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" v-on:click="filterVacantesVigentes()">Vacantes Vigentes</a></li>
                    <li><a class="dropdown-item" v-on:click="filterVacantesCaducadas()">Vacantes Caducadas</a></li>
                    <li><a class="dropdown-item" v-on:click="filterVacantesTodas()">Todas</a></li>
                </ul>
            </div>
        </div>
        <div style="width: 100%; padding: 2rem;">
            <table class="table table-bordered  align-middle table-responsive table-hover">
                <thead>
                    <tr align="center">
                        <th scope="col">#</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Unidad/División</th>
                        <th scope="col">Status</th>
                        <th scope="col">Fecha de Publicación</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Modalidad</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Opciones</th>
                    </tr>
                </thead>
                <tbody v-if="response.loading">
                    <tr>
                        <td colspan="8">
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border text-danger" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else v-for="(item, i) in dataPage" :key="i + 1"
                    style="font-family: 'Poppins';text-align: center;">
                    <tr align="center">
                        <td>{{ i + 1 }}</td>
                        <td>{{ item.titulo }}</td>
                        <td>{{ item.dashboard.company }}</td>
                        <td v-if="moment(item.createdAt) < this.vigencia" style="color: orangered;">
                            Vacante Caducada</td>
                        <td v-else style="color: green;">Vacante Vigente</td>
                        <td>{{ moment(item.createdAt).tz(`${userTimeZone}`).format("DD/MM/YYYY") }}</td>
                        <td>{{ item.estado }}</td>
                        <td>{{ item.modalidad }}</td>
                        <td v-if="item.type === 'Becario'">Becario</td>
                        <td v-else>Profesionista</td>
                        <td>
                            <div class="btn-margin-main">
                                <button class="btn-style" title="Copiar Link De Vacante"
                                    v-show="moment(item.createdAt) > this.vigencia"
                                    v-if="item.vacanteSuspended === false" v-on:click="linkVacante(item._id)">
                                    <box-icon name='copy-alt' color="#03337A"></box-icon>
                                </button>
                                <!-- <button class="btn-style" title="Cambiar Vigencia"
                                    v-show="moment(item.createdAt) < this.vigencia" v-on:click="changeVigencia(item)">
                                    <box-icon name='time-five' color="#03337A"></box-icon>
                                </button> -->
                                <button class="btn-style" title="Editar Vacante" v-on:click="editVacante(item._id)">
                                    <box-icon name='edit' type='solid' color="#03337A"></box-icon>
                                </button>
                                <button class="btn-style" title="Ver Detalles"
                                    v-show="moment(item.createdAt) > this.vigencia"
                                    v-if="item.vacanteSuspended === false" v-on:click="detalleVacante(item._id)">
                                    <box-icon name='desktop' color="#03337A"></box-icon>
                                </button>
                                <button class="btn-style" title="Pausar Vacante"
                                    v-show="moment(item.createdAt) > this.vigencia"
                                    v-if="item.vacanteSuspended === false" v-on:click="restartVigencia(item, true)">
                                    <box-icon name='pause-circle' color="#03337A"></box-icon>
                                </button>
                                <button class="btn-style" title="Reanudar Vacante"
                                    v-show="moment(item.createdAt) > this.vigencia"
                                    v-if="item.vacanteSuspended === true" v-on:click="restartVigencia(item, false)">
                                    <box-icon name='play-circle' color="#03337A"></box-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Paginador -->
            <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                    <li class="page-item" v-on:click="getPreviousPage()">
                        <a class="page-link" href="#">Anterior</a>
                    </li>
                    <li v-for="(page, i) in totalPagesNumber" :key="i + 1" v-on:click="getDataPages(page)"
                        v-bind:class="isActive(page)" class="page-item">
                        <a class="page-link" href="#">{{ page }}</a>
                    </li>
                    <li class="page-item" v-on:click="getNextPage()">
                        <a class="page-link" href="#">Siguiente</a>
                    </li>
                </ul>
                <ul class="pagination justify-content-center">
                    <li class="page-item">
                        Página <b>{{ this.actual }}</b> de <b>{{ totalPagesNumber }}</b> - Total de <b>{{
                this.listVacantes.length }}</b> vacantes disponibles
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import { CompanyService } from "@/services";
import moment from 'moment-timezone';


export default {
    data: () => ({
        moment: moment,
        dataPostulate: [],
        search: "",
        searchInput: "",
        userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        vigencia: moment().subtract(2, 'months'),
        tabs: [
            {
                title: "Datos Personales",
                active: true,
                checked: true,
            },
            {
                title: "Datos Económicos",
                active: false,
                checked: false,
            },
        ],
        listVacantes: [],
        listVacantesTemp: [],
        modalPostulantes: null,
        response: {},
        table: null,
        elementSearch: "",
        elementPage: 10,
        dataPage: [],
        totalPagesNumber: 0,
        actual: 1,
        countElements: 0,
    }),
    mounted() {
        this.getCompany();
        this.filterVacantesActivas();
    },
    methods: {
        //Method para traer la information de las vacantes de la empresa
        async getCompany() {
            try {
                this.response.loading = true
                await CompanyService.getVacantes({ search: this.search }).then((resp) => {
                    let { data } = resp.data;
                    this.listVacantes = data;
                    this.listVacantesTemp = data;
                    this.response.loading = false
                    this.totalPages(this.listVacantes.length)
                    this.listVacantes.length > 0 ? this.getDataPages(1) : this.response.loading = true;
                }).catch((e) => {
                    this.$swal({
                        position: "top-end",
                        toast: true,
                        icon: "error",
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 5000,
                    });
                });
            } catch (error) {
                this.response.loading = false
            }
        },
        editVacante(id) {
            window.location.href = ("/vacantes/empresa/editarvacante/" + id);
        },
        //#region Filtros Vacantes
        filter(vacante) {
            if (this.searchInput != "") {
                return vacante.titulo.toLowerCase().match(this.searchInput) || vacante.ubicacion.toLowerCase().match(this.searchInput) || vacante.modalidad.toLowerCase().match(this.searchInput) || vacante.nameCompany.toLowerCase().match(this.searchInput.toLowerCase());
            }
            else {
                return vacante;
            }
        },
        filterVacantesActivas() {
            let vacantesfilter = this.listVacantesTemp.filter(vac => {
                return vac.status === true;
            });
            this.listVacantes = vacantesfilter;
            console.log(vacantesfilter);
        },
        filterVacantesRevision() {
            let vacantesfilter = this.listVacantesTemp.filter(vac => {
                return vac.status === false;
            });
            this.listVacantes = vacantesfilter;
        },
        filterVacantesFecha(vacantes) {
            vacantes.sort((a, b) => {
                if (a.createdAt == b.createdAt) {
                    return 0;
                }
                if (a.createdAt > b.createdAt) {
                    return -1;
                }
                return 1;
            });
            return vacantes;
        },
        filterVacantesVigentes() {
            let vacantesfilter = this.listVacantesTemp.filter(vac => {
                return moment(vac.createdAt) > this.vigencia;
            });
            this.listVacantes = vacantesfilter;
            this.totalPages(vacantesfilter.length); // Actualiza el número total de páginas
            this.getDataPages(1); // Mostrar la primera página después de aplicar el filtro
        },
        filterVacantesCaducadas() {
            let vacantesfilter = this.listVacantesTemp.filter(vac => {
                return moment(vac.createdAt) < this.vigencia;
            });
            this.listVacantes = vacantesfilter;
            this.totalPages(vacantesfilter.length); // Actualiza el número total de páginas
            this.getDataPages(1); // Mostrar la primera página después de aplicar el filtro
        },
        filterVacantesTodas() {
            this.listVacantes = this.listVacantesTemp;
            this.totalPages(this.listVacantes.length); // Actualiza el número total de páginas
            this.getDataPages(1); // Mostrar la primera página después de aplicar el filtro
        },
        async totalPages(data) {
            console.log("Cantidad total de elementos:", data); // Verificar la cantidad total de elementos
            this.totalPagesNumber = Math.ceil(data / this.elementPage);
        },
        getDataPages(numberPage) {
            this.actual = numberPage;
            const start = (numberPage - 1) * this.elementPage;
            const end = Math.min(start + this.elementPage, this.listVacantes.length); // Asegura que end no sea mayor que la longitud de listVacantes
            const startIndex = (numberPage - 1) * this.elementPage; // Índice absoluto de inicio de la página
            this.dataPage = this.listVacantes.slice(start, end).map((item, index) => ({
                ...item,
                index: startIndex + index + 1
            }));
        },
        getPreviousPage() {
            if (this.actual > 1) {
                this.actual--;
            }
            this.getDataPages(this.actual);
        },
        getNextPage() {
            if (this.actual < this.totalPagesNumber) {
                this.actual++;
            }
            this.getDataPages(this.actual);
        },
        isActive(numberPage) {
            return numberPage == this.actual ? "active" : "";
        },
        //#endregion

        //TODO:Metodo Para Cambiar Vigencia de Vacantes
        changeVigencia(vacante) {
            let user = JSON.parse(localStorage.getItem("user") || null);
            let { _id } = user;
            let { createdAt } = vacante;
            let newDate = new Date(moment(new Date()));
            let today = newDate.toISOString();
            let idVacante = vacante._id;
            let updateVigencia = {
                idVacante: idVacante,
                fechaVacante: createdAt,
                newDateVacante: today,
                companyId: this.$route.params.companyId = _id,
            }
            CompanyService.updateVigenciaVacante(updateVigencia).then((response) => {
                this.$router.push("/vacantes/empresa");
                this.$swal({
                    position: "top-center",
                    icon: "success",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            }).catch((e) => {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    title: e.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            });
        },
        //TODO:Metodo Para Cambiar Vigencia de Vacantes
        restartVigencia(vacante, state) {
            let user = JSON.parse(localStorage.getItem("user") || null);
            let { _id } = user;
            let idVacante = vacante._id;
            let updateRestartVacante = {
                vacanteSuspended: state,
                companyId: this.$route.params.companyId = _id,
                vacanteId: this.$route.params.vacanteId = idVacante,
            }
            CompanyService.updateRestartVacante(updateRestartVacante).then((response) => {
                this.$swal({
                    position: "top-center",
                    icon: "success",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000,
                });
                window.location.href = "/vacantes/empresa";
            }).catch((e) => {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    title: e.message,
                    showConfirmButton: false,
                    timer: 5000,
                });
            });
        },
        linkVacante(id) {
            let aux = document.createElement("input");
            aux.setAttribute("value", "https://mundouniversitario.mx/vacantes/vista/" + id);
            document.body.appendChild(aux);
            aux.select();
            document.execCommand("copy");
            document.body.removeChild(aux);
            this.$swal({
                position: "top-center",
                icon: "success",
                title: "Enlace Copiado Correctamente",
                showConfirmButton: false,
                timer: 3000,
            });
        },
        detalleVacante(id) {
            window.location.href = ("https://mundouniversitario.mx/vacantes/vista/" + id);
        }

    },
};
</script>

<style land="scss" scoped>
@import "../styles/vacantes.scss";
</style>
